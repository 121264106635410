import { useQuery } from '@tanstack/react-query';

import { dealerRepository } from 'repositories/Dealer/DealerRepository';
import * as constants from 'components/DealerDirectory/constants';
import { PAGE } from 'helpers/pages';

export const makeUseDealerAutoCompleteKey = (search: string | null) =>
  ['dealerDirectoryAutocomplete', search] as const;

type UseDealerAutoCompleteProps = {
  search: string;
  isExpanded: boolean;
};

const defaultItem = {
  value: '',
  displayName: constants.DEFAULT_TERMS_TEXT,
};

export const useDealerAutoComplete = (props: UseDealerAutoCompleteProps) => {
  const { search, isExpanded } = props;
  return useQuery({
    queryKey: makeUseDealerAutoCompleteKey(search),
    queryFn: () => dealerRepository.autocomplete(encodeURIComponent(search)),
    staleTime: Infinity,
    enabled: isExpanded,
    meta: { tags: [PAGE.FIND_A_DEALER] },
    select(data) {
      return [defaultItem, ...data];
    },
  });
};
