import type { ListGroupProps } from 'components/ToolkitV2/SearchableDropdown/components/ListGroup/ListGroup.type';
import * as Styled from 'components/ToolkitV2/SearchableDropdown/components/ListGroup/ListGroup.styled';

const ListGroup = (props: ListGroupProps) => {
  const { children, title } = props;
  return (
    <Styled.Group>
      {title && <Styled.Title>{title}</Styled.Title>}
      {children}
    </Styled.Group>
  );
};

export default ListGroup;
