import styled from 'styled-components';

import { Item } from 'components/ToolkitV2/SearchableDropdown/components/ListItem/ListItem.styled';

export const List = styled.ul`
  ${({ theme }) => theme.fontSize.M16};
`;

export const SItem = styled(Item)`
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.M16}`};
`;

export const NoResults = styled(Item)`
  color: ${({ theme }) => theme.colors.GREY_DARK};
  cursor: default;
  padding: ${({ theme }) => `${theme.spacing.S12} ${theme.spacing.M16}`};
  width: 100%;
  height: 100%;

  :hover {
    background-color: ${({ theme }) => theme.colors.WHITE};
  }
`;
