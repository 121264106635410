import type { ListProps } from 'components/ToolkitV2/SearchableDropdown/components/List/List.type';
import { SkeletonBlock } from 'components/ToolkitV2/Skeleton/Skeleton';
import * as Styled from 'components/ToolkitV2/SearchableDropdown/components/List/List.styled';

const List = (props: ListProps) => {
  const { children, isLoading, loadingCount, noResults, dataTestId } = props;
  const count = loadingCount || 10;
  if (isLoading) {
    return (
      <Styled.List data-testid={`${dataTestId}-loading`}>
        {Array.from({ length: count }, (_, index) => (
          <Styled.SItem key={index}>
            <SkeletonBlock w={'100%'} h={24} />
          </Styled.SItem>
        ))}
      </Styled.List>
    );
  }
  if (noResults) {
    return (
      <Styled.List data-testid={`${dataTestId}-no-results`}>
        <Styled.NoResults>No results found</Styled.NoResults>
      </Styled.List>
    );
  }
  return <Styled.List data-testid={dataTestId}>{children}</Styled.List>;
};

export default List;
