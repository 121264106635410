import type { ChangeEventHandler } from 'react';
import { useState } from 'react';

type Defaults = {
  /* The initial value of the search text */
  search: string | null;
  /* The initial focus state of the searchbox */
  focus: boolean;
  /* The threshold at which the dropdown becomes active */
  threshold: number;
  /* Whether the dropdown should default to open when focussed */
  defaultToOpen: boolean;
};

type Props = {
  /* Whether the dropdown is currently selected */
  isSelected: boolean;
  /* The value of a selected item, if selected */
  selectedValue: string | null;
  /* Overrides for default values */
  overrides?: Partial<Defaults>;
};

const defaults: Defaults = {
  search: '',
  focus: false,
  threshold: 3,
  defaultToOpen: true,
};

export const useSearchableDropdown = (props: Props) => {
  const { isSelected, selectedValue } = props;
  const mergedProps = { ...defaults, ...props?.overrides };
  const [search, setSearch] = useState(mergedProps.search ?? '');
  const [isFocus, setIsFocus] = useState(mergedProps.focus);

  const isOpen =
    isSelected &&
    ((mergedProps.defaultToOpen && search.length === 0) ||
      search.length > mergedProps.threshold);

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    setSearch(event.target.value);
  const onFocus = () => setIsFocus(true);
  const onBlur = () => setIsFocus(false);
  const onClear = () => setSearch('');
  const highlight = (text: string) => {
    if (selectedValue && search.length < 1) {
      return boldIfSelected(text, selectedValue);
    } else return boldIfMatch(text, search);
  };

  return {
    search,
    isOpen,
    isFocus,
    onChange,
    onFocus,
    onBlur,
    onClear,
    highlight,
  };
};

const boldIfMatch = (text: string, searchTerm: string) => {
  if (searchTerm === '') return text;
  const textUpperCase = text.toUpperCase();
  const termUpperCase = searchTerm.toUpperCase();
  const startIndex = textUpperCase.indexOf(termUpperCase);
  if (startIndex === -1) return text;
  const endIndex = startIndex + termUpperCase.length;
  const start = text.substring(0, startIndex);
  const middle = text.substring(startIndex, endIndex);
  const end = text.substring(endIndex, text.length);
  return (
    <span>
      {start}
      <b>{middle}</b>
      {end}
    </span>
  );
};

const boldIfSelected = (text: string, selected: string) => {
  if (text !== selected) return text;
  return <b>{text}</b>;
};
