import { useState, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { SearchOutlineIc, CloseOutlineIc } from '@dsch/dd-icons';

import type { SearchboxProps } from 'components/ToolkitV2/SearchableDropdown/components/Searchbox/Searchbox.type';
import * as Styled from 'components/ToolkitV2/SearchableDropdown/components/Searchbox/Searchbox.styled';

const Searchbox = (props: SearchboxProps) => {
  const {
    className,
    value,
    isFocus = false,
    dataTestId,
    onClear,
    onEnter,
    onFocus,
    onBlur,
    ...rest
  } = props;
  const [isFocussed, setIsFocussed] = useState(isFocus);
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  const onKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') onEnter && onEnter();
  };

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
    setIsFocussed(true);
    onFocus && onFocus(event);
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    setIsFocussed(false);
    onBlur && onBlur(event);
  };

  const focusColor = isFocussed ? theme.colors.GREY_DARKER : theme.colors.GREY;
  const cancelable = Boolean(value && value.toString().length > 0);

  useEffect(() => {
    if (isFocus) inputRef.current?.focus();
  }, [isFocus]);

  return (
    <Styled.Wrapper className={className}>
      <Styled.Container cancelable={cancelable}>
        <SearchOutlineIc height={20} width={20} color={focusColor} />
        <Styled.Input
          value={value}
          autoComplete="off"
          type="search"
          onKeyPress={onKeyPress}
          data-testid={dataTestId}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          {...rest}
        />
        {cancelable && (
          <Styled.Button onClick={onClear} data-testid="searchbox-clear">
            <CloseOutlineIc width={12} height={12} />
          </Styled.Button>
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Searchbox;
