import type { BaseFilterValue } from 'domains/Filter';
import type { AutocompleteFilterProps } from 'components/DealerDirectory/components/DealersFilter/components/AutocompleteFilter/AutocompleteFilter.typed';
import useDebounce from 'hooks/useDebounce';
import { useDealerAutoComplete } from 'components/DealerDirectory/queries/useDealerAutoComplete';
import {
  SearchableDropdown,
  useSearchableDropdown,
} from 'components/ToolkitV2/SearchableDropdown/SearchableDropdown';

const AutocompleteFilter = (props: AutocompleteFilterProps) => {
  const { initialSelected, onSelect, isOpen: isExpanded } = props;
  const { search, isOpen, onChange, onFocus, onBlur, onClear, highlight } =
    useSearchableDropdown({
      isSelected: isExpanded,
      selectedValue: initialSelected.displayName,
      overrides: { search: initialSelected.value, threshold: 0 },
    });
  const debouncedSearch = useDebounce(search, 300);
  const {
    data: dealers,
    isLoading,
    isSuccess,
  } = useDealerAutoComplete({
    search: debouncedSearch,
    isExpanded,
  });

  const filterValue: BaseFilterValue = {
    displayName: search,
    value: search,
  };

  const handleSelect = (item: BaseFilterValue) => {
    onClear();
    onSelect(item);
  };

  return (
    <SearchableDropdown>
      <SearchableDropdown.Searchbox
        value={search}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onClear={onClear}
        onEnter={() => handleSelect(filterValue)}
        placeholder={'Type Dealership Name'}
        dataTestId="dealer-dropdown-input"
      />
      {isOpen && (
        <SearchableDropdown.List
          loadingCount={10}
          isLoading={isLoading}
          dataTestId="dealer-dropdown-list"
        >
          {isSuccess &&
            dealers.map((dealer) => (
              <SearchableDropdown.ListItem
                key={dealer.value}
                onClick={() => handleSelect(dealer)}
                dataTestId="dealer-dropdown-listitem"
              >
                {highlight(dealer.displayName)}
              </SearchableDropdown.ListItem>
            ))}
        </SearchableDropdown.List>
      )}
    </SearchableDropdown>
  );
};

export { AutocompleteFilter };
