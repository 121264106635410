import type { ListItemProps } from 'components/ToolkitV2/SearchableDropdown/components/ListItem/ListItem.type';
import * as Styled from 'components/ToolkitV2/SearchableDropdown/components/ListItem/ListItem.styled';

const ListItem = (props: ListItemProps) => {
  const { children, dataTestId, disabled = false, onClick } = props;
  return (
    <Styled.Item disabled={disabled} data-testid={dataTestId}>
      <Styled.Clickable onClick={onClick} disabled={disabled}>
        {children}
      </Styled.Clickable>
    </Styled.Item>
  );
};

export default ListItem;
